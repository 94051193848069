<template>
  <div>
    <card-component
      title="Login"
      icon="lock"
    >
      <router-link
        slot="button"
        to="/"
        class="button is-small"
      >
        Dashboard
      </router-link>

      <form
        method="POST"
        @submit.prevent="submit"
      >
        <b-field label="E-mail Address">
          <b-input
            v-model="form.email"
            name="email"
            required
          />
        </b-field>

        <b-field label="Password">
          <b-input
            v-model="form.password"
            type="password"
            name="password"
            required
          />
        </b-field>

        <b-field>
          <b-checkbox
            v-model="form.remember"
            type="is-black"
            class="is-thin"
          >
            Remember me
          </b-checkbox>
        </b-field>

        <hr>

        <b-field grouped>
          <div class="control">
            <b-button
              native-type="submit"
              type="is-black"
              :loading="isLoading"
            >
              Login
            </b-button>
          </div>
        </b-field>
      </form>
    </card-component>

    <div style="text-align: center;position: fixed;bottom: 20px;width: 100%;left: 0"><a href="https://beian.miit.gov.cn/" target="_blank">粤ICP备10063180号</a></div>

  </div>
</template>

<script>
import CardComponent from '@/components/CardComponent.vue'
import api from '@/api.js'

export default {
  name: 'Login',
  components: { CardComponent },
  data () {
    return {
      isLoading: false,
      loginResponse: null,
      header: {
        headers:
          {
            'Content-Type': 'application/x-www-form-urlencoded'
          }
      },
      form: {
        email: '',
        password: '',
        remember: false
      }
    }
  },
  methods: {
    submit () {
      this.isLoading = true
      api.call('user/login', {
        userName: this.form.email,
        password: this.form.password
      }).then ( function(response){
        const loginstatus = response.data[0]
        if (loginstatus === '1'){
          this.$store.commit('user', {
            name: response.data[4],
            email: response.data[3],
            avatar: response.data[5]
          })
          this.$store.commit('isLogin', true)
          this.$store.commit('token', response.data[2])
          this.$router.push({ path: '/', replace: true })
        } else {
          this.$buefy.snackbar.open({
            message: response.data[1],
            queue: false
          })
        }
        this.isLoading = false
      }.bind(this))
    }
  }
}
</script>
