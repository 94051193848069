import axios from 'axios'
import store from './store'
import saveAs from 'file-saver'
let apiURL = ''
if (process.env.NODE_ENV === 'production' ){
  apiURL = 'http://106.75.154.98/server/'
} else {
  apiURL = 'http://localhost:7777/'
}
const configURL = '/'
export default {
  publicURL: apiURL,
  call (api, params) {
    const headers = { headers: { 'x-auth-token': store.state.token } }
    return axios.post(apiURL + api, params, headers)
      .then (function (response){
        return response
      })
  },
  get (api) {
    const headers = { headers: { 'x-auth-token': store.state.token, 'content-type': 'application/json' } }
    return axios.get(apiURL + api, headers)
      .then (function (response){
        return response
      })
  },
  download (api) {
    const headers = { headers: { 'x-auth-token': store.state.token, 'content-type': 'application/json', 'responseType': 'blob' } }
    return axios.get(apiURL + api, headers)
      .then (function (response){
        saveAs( response.data, 'downloaded-file.pdf' )
      }).catch(console.error)
  },
  post (api, params) {
    const headers = { headers: { 'x-auth-token': store.state.token, 'content-type': 'application/x-www-form-urlencoded' } }
    return axios.post(apiURL + api, params, headers)
      .then (function (response){
        return response
      })
  },
  config (api) {
    const headers = { headers: { 'x-auth-token': store.state.token, 'content-type': 'application/json' } }
    return axios.get(configURL + api, headers)
      .then (function (response){
        return response
      })
  }
}
